import 'typeface-lato'
import { createGlobalStyle } from 'styled-components'

import {
  brightBlue,
  bgColor,
  onMobile,
  onTablet,
  onDesktop,
  onHighDef,
} from 'utils/cssVariables'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: ${bgColor};
    color: white;

    font-size: 16px;

    ${onTablet} {
      font-size: 18px;
    }
    ${onDesktop} {
      font-size: 20px;
    }
    ${onHighDef} {
      font-size: 24px;
    }
  }
  
  h1 {
    font-size: 3.5rem;

    ${onMobile} {
      font-size: 2.8rem;
    }
  }

  .mdi-icon {
    height: 2.625rem;
    width: 2.625rem;
    transition: fill 0.2s ease;
  }

  .about-text {
    a {
      font-weight: 700;
      text-decoration: none;
      transition: color 0.2s ease;

      &:visited, &:link {
        color: white;
      }

      &:active,
      &:hover {
        color: ${brightBlue};
      }
    }
  }

  .sr-only {
    position: absolute;
    left: -10000px;
    height: 1px;
    width: 1px;
  }
`
