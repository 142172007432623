import { Field } from 'formik'
import styled from 'styled-components'

export const ProfilePic = styled.img`
  display: block;
  height: 12.5rem;
  width: 12.5rem;
  border-radius: 50%;
  border: 0.125rem solid white;
  margin: 0 auto 1.25rem auto;
`

export const Text = styled.p`
  align-self: flex-start;
  line-height: 1.7;
`

export const H1NoMargin = styled.h1`
  margin: 0;
  line-height: 1em;
`

export const HiddenInput = styled(Field)`
  display: none;
`

export const List = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0.5rem 0 0 0;
`

export const ListItem = styled.li`
  position: relative;
  line-height: 1.5rem;
  margin-bottom: 0.2rem;
  padding-left: 1rem;

  &::before {
    position: absolute;
    left: 0;
    content: '‣ ';
  }
`
