import { Form as FormikForm } from 'formik'
import styled from 'styled-components'
import {
  brightBlue,
  simpleTransition,
  fancyTransition,
} from 'utils/cssVariables'

export const Form = styled(FormikForm)`
  margin: 1.25rem 0;
  width: 100%;

  > div {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 0.33rem 0 1rem 0;
    transition: ${fancyTransition};

    &.active {
      padding: 1rem 0;
    }
  }

  textarea,
  input {
    background-color: rgba(0, 0, 0, 0.3);
    border: 0px;
    border-bottom: 0.125rem solid white;
    border-radius: 0px;
    padding: 0.5rem 0.6rem;
    color: white;
    width: 100%;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    resize: none;
  }
`

export const Label = styled.label`
  position: absolute;
  transform: translateY(0.55rem);
  left: 0.6rem;
  color: #ffffff;
  transition: ${fancyTransition};
  -webkit-backface-visibility: hidden;
  pointer-events: none;
  font-size: 1rem;

  .req {
    margin: 0.25rem;
    color: ${brightBlue};
    transition: ${simpleTransition};
  }

  &.active {
    color: ${brightBlue};
    font-size: 0.8rem;
    left: 2px;
    transform: translateY(-1.25rem);
    .req {
      opacity: 0;
    }
  }
`
