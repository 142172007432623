import React, { useState, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './containers/Home'
import { ContentWrapper, SiteWrapper } from './components/styled'
import { CONTACT, ABOUT, SKILLS, EXPERIENCE } from './constants'

const About = React.lazy(() => import('./containers/About'))
const Contact = React.lazy(() => import('./containers/Contact'))
const Experience = React.lazy(() => import('./containers/Experience'))
const Skills = React.lazy(() => import('./containers/Skills'))

const App: React.FC = () => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => setMounted(true), 200)
  }, [])

  return (
    <SiteWrapper>
      <ContentWrapper>
        <React.Suspense fallback={null}>
          <Routes>
            <Route path={ABOUT} element={<About />} />
            <Route path={CONTACT} element={<Contact />} />
            <Route path={EXPERIENCE} element={<Experience />} />
            <Route path={SKILLS} element={<Skills />} />
            <Route path="/" element={<Home mounted={mounted} />} />;
          </Routes>
        </React.Suspense>
      </ContentWrapper>
    </SiteWrapper>
  )
}

export default App
