import React from 'react'
import { Link } from 'react-router-dom'
import { PosedPage } from 'components/posed'
import { Header } from 'components/styled'
import { ABOUT, CONTACT, EXPERIENCE, SKILLS } from 'constants/index'
import { Info, Mail, File, Code } from 'react-feather'
import { H1, Line, StyledIcons, Icon } from './components'

const Home = ({ mounted }: { mounted: boolean }) => {
  const pageInitialPose = mounted ? 'hide' : 'show'
  const initialPose = mounted ? 'show' : 'hide'

  return (
    <PosedPage animate="show" initial={pageInitialPose}>
      <Header className="homepage">
        <H1 animate="show" initial={initialPose}>
          <Line>hi,</Line>
          <br />
          <Line>
            i&apos;m
            <span className="highlight-blue"> ciaran</span>
          </Line>
          <br />
          <Line>
            and i <span className="highlight-yellow">code</span>
          </Line>
        </H1>

        <StyledIcons animate="show" initial={initialPose}>
          <Icon>
            <Link to={ABOUT}>
              <span className="sr-only">About me</span>
              <Info />
            </Link>
          </Icon>

          <Icon>
            <Link to={SKILLS}>
              <span className="sr-only">Skills</span>
              <Code />
            </Link>
          </Icon>

          <Icon>
            <Link to={EXPERIENCE}>
              <span className="sr-only">Experience</span>
              <File />
            </Link>
          </Icon>

          <Icon>
            <Link to={CONTACT}>
              <span className="sr-only">Contact</span>
              <Mail />
            </Link>
          </Icon>
        </StyledIcons>
      </Header>
    </PosedPage>
  )
}

export default Home
