import styled from 'styled-components'
import { bgColor, brightBlue, simpleTransition } from 'utils/cssVariables'

export const Button = styled.button`
  background-color: ${bgColor};
  border: 0.125rem solid white;
  border-radius: 0px;
  color: white;
  cursor: pointer;
  float: right;
  font-family: 'Lato', sans-serif;
  font-size: 1.1rem;
  height: 2.5rem;
  width: 7.5rem;
  margin-top: 0.6rem;
  transition: ${simpleTransition};

  &:hover {
    color: ${brightBlue};
    border-color: ${brightBlue};
  }
`

export const NavButton = styled.button`
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
  height: 3rem;
  width: 3rem;

  svg {
    fill: white;
    transition: ${simpleTransition};
  }
  &:hover {
    svg {
      fill: ${brightBlue};
    }
  }
`
