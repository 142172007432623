import styled from 'styled-components'
import {
  bgColor,
  brightBlue,
  navBgColor,
  onMobile,
  yellow,
} from 'utils/cssVariables'
import { PosedDiv } from '../posed'

export const Header = styled.header`
  font-weight: 500;
  padding: 1rem 1.5rem;

  &:not(.homepage) {
    h1 {
      text-align: center;
    }
  }

  &.homepage {
    display: grid;
    justify-content: center;
  }

  .highlight-blue {
    color: ${brightBlue};
  }
  .highlight-yellow {
    color: ${yellow};
  }

  @media (max-width: 767px) {
    padding: 1.25rem 0;
  }

  h1 {
    margin: 0px;
  }
`

export const AlignEnd = styled(PosedDiv)`
  display: flex;
  align-items: flex-end;
`

export const FullWidthDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

// 7rem is ContentWrapper's top/bottom-padding
export const FullPageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 7rem);

  ${onMobile} {
    min-height: calc(100vh - 2.5rem);
  }
`

export const PageTitleDiv = styled(PosedDiv)`
  display: flex;
  align-items: flex-end;
  border-bottom: 0.125rem solid white;
  margin-bottom: 2rem;
  padding-bottom: 1.25rem;
`

export const SiteWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  width: 100%;

  background-color: ${bgColor};
  color: white;
  font-family: 'Lato', sans-serif;
`

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  width: 32rem;
  max-width: 90vw;
  padding: 3.5rem 0;
  margin: 0 auto;

  ${onMobile} {
    padding: 1.5rem 0;
  }
`

export const AbsoluteDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.8s ease-out;
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-content: center;
  background-color: ${bgColor};
  height: 100vh;
  width: 100%;
`

export const Navbar = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: ${navBgColor};
  align-content: center;
`

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  margin-top: 2rem;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    transition: all 0.2s ease;
    margin: 0 0.25rem;

    &:hover {
      color: ${brightBlue};
      text-decoration: none;
    }
  }

  i,
  svg {
    font-size: 1.2rem;
    margin-right: 0.3rem;
    height: 1.2rem;
    width: 1.2rem;
  }
`
