export const ABOUT = '/about'
export const CONTACT = '/contact'
export const EXPERIENCE = '/experience'
export const HOME = '/'
export const SKILLS = '/skills'

// Contact form fields
export const NAME = 'name'
export const EMAIL = 'email'
export const MESSAGE = 'message'
