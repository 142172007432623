// Colors
export const brightBlue = '#56bdc2'
export const yellow = '#faa12d'
export const bgColor = '#141b1f'
export const navBgColor = '#222222'

// Transitions
export const simpleTransition = 'all 0.2s ease'
export const fancyTransition = 'all 0.2s cubic-bezier(.45,.05,.55,.95)'

// Media queries
export const onMobile = '@media screen and (max-width: 767px)'
export const onTablet = '@media screen and (max-width: 991px)'
export const onDesktop = '@media screen and (min-width: 992px)'
export const onHighDef = '@media screen and (min-width: 1441px)'
