import React from 'react'
import { motion, Variants } from 'framer-motion'
import styled from 'styled-components'
import { MotionComponent } from 'types/motion'

const OFFSET = 200

const PageTransition: MotionComponent<'div'> = (props) => (
  <motion.div
    initial="hide"
    {...props}
    variants={{
      show: {
        y: 0,
        opacity: 1,
        transition: { delay: 0.3, duration: 0.15 },
      },
      hide: { y: 20, opacity: 0 },
    }}
  />
)

export const PosedPage = styled(PageTransition)`
  position: relative;
  width: 100%;
`

export const StaggerChildren: MotionComponent<'div'> = (props) => (
  <motion.div
    initial="hide"
    {...props}
    variants={{
      show: {
        opacity: 1,
        transition: { delayChildren: 0.3, staggerChildren: 0.15 },
      },
      hide: { opacity: 0 },
    }}
  />
)

export const Div: MotionComponent<'div', { enterLeft?: boolean }> = ({
  enterLeft,
  ...props
}) => (
  <motion.div
    {...props}
    custom={{ offset: enterLeft ? -OFFSET : OFFSET }}
    variants={{
      show: {
        x: 0,
        opacity: 1,
        transition: { type: 'tween' },
      },
      hide: ({ offset }: { offset: number }) => ({
        opacity: 0,
        x: offset,
      }),
    }}
  />
)

const positionConfig: Variants = {
  center: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      staggerChildren: 0.5,
    },
  },
  right: { x: OFFSET, y: 0, opacity: 0 },
  left: { x: -OFFSET, y: 0, opacity: 0 },
  top: { x: 0, y: -OFFSET, opacity: 0 },
  bottom: { x: 0, y: OFFSET, opacity: 0 },
}

export const PosedH1: MotionComponent<'h1'> = (props) => (
  <motion.h1 variants={positionConfig} {...props} />
)
export const PosedH2: MotionComponent<'h2'> = (props) => (
  <motion.h2 variants={positionConfig} {...props} />
)
export const PosedDiv: MotionComponent<'div'> = (props) => (
  <motion.div variants={positionConfig} {...props} />
)
