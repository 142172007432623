import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { MotionComponent } from 'types/motion'
import { brightBlue } from 'utils/cssVariables'

export const H1: MotionComponent<'h1'> = (props) => (
  <motion.h1
    variants={{
      show: {
        transition: {
          staggerChildren: 0.9,
        },
      },
    }}
    {...props}
  />
)

export const Line: MotionComponent<'span'> = (props) => (
  <motion.span
    variants={{
      show: {
        y: 0,
        opacity: 1,
      },
      hide: {
        y: 20,
        opacity: 0,
      },
    }}
    {...props}
  />
)

const Icons: MotionComponent<'div'> = (props) => (
  <motion.div
    variants={{
      show: {
        scaleX: 1,
        transition: {
          staggerChildren: 0.1,
          delay: 2.8,
          delayChildren: 3,
        },
      },
      hide: {
        scaleX: 0,
      },
    }}
    {...props}
  />
)

export const Icon: MotionComponent<'div'> = (props) => (
  <motion.div
    variants={{
      show: {
        scaleX: 1,
        opacity: 1,
      },
      hide: {
        scaleX: 0,
        opacity: 0,
      },
    }}
    {...props}
  />
)

export const StyledIcons = styled(Icons)`
  display: flex;
  justify-content: space-between;
  border-top: 0.125rem solid white;
  color: white;
  margin-top: 1.25rem;
  padding-top: 1.25rem;

  svg {
    color: white;
    height: 2.625rem;
    width: 2.625rem;
    transition: all 0.2s ease;
  }
  a:hover {
    svg {
      color: ${brightBlue};
    }
  }
`
